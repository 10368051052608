import React, { useState, useEffect } from "react";
import { Form, Checkbox, TimePicker, Button, message } from "antd";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import moment from "moment";

const PickupInfo = ({ business }) => {
  const [form] = Form.useForm();
  const [is_enabled, setEnabled] = useState(false);

  useEffect(() => {
    // Aquí harás la petición GET al endpoint para cargar los datos
    axiosWithAuth()
      .get(`/api/restaurant/shipping/pickupGetSettings/${business.id}`)
      .then((response) => {
        const data = response.data;
        setEnabled(data.is_enabled);
        form.setFieldsValue({
          is_enabled: data.is_enabled,
          preparation_time: data.preparation_time
            ? moment(data.preparation_time, "HH:mm")
            : null,
        });
      })
      .catch((error) => {
        message.error("Error loading pickup settings.");
      });
  }, [business.id, form]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const payload = {
        is_enabled: values.is_enabled,
        preparation_time: values.preparation_time
          ? values.preparation_time.format("HH:mm")
          : null,
        id_restaurant: business.id,
      };

      await axiosWithAuth().put(
        `/api/restaurant/shipping/pickupSettingsUpdate`,
        payload
      );
      message.success("Pickup settings updated successfully!");
    } catch (error) {
      message.error("Failed to update pickup settings.");
    }
  };

  return (
    <div className="content-box" style={{ padding: 20 }}>
      <Form form={form} layout="vertical">
        <Form.Item name="is_enabled" valuePropName="checked">
          <Checkbox onChange={(e) => setEnabled(e.target.checked)}>
            Enable Pickup
          </Checkbox>
        </Form.Item>

        <Form.Item name="preparation_time" label="Estimated Preparation Time">
          <TimePicker format="HH:mm" disabled={!is_enabled} showNow={false} />
        </Form.Item>

        <Button type="primary" onClick={handleSave} disabled={!is_enabled}>
          Save
        </Button>
      </Form>
    </div>
  );
};

export default PickupInfo;
