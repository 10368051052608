import React, { useState } from "react";
import { Tabs } from "antd";
import DeliveryInfo from "./DeliveryInfo";
import DeliveryPricesInfo from "./DeliveryPricesInfo";
import ContentPageTitle from "../../../../common/ContentPageTitle";

const DeliverySettings = (props) => {
  const { business } = props;

  const [deliveryType, setDeliveryType] = useState("fixed");

  return (
    <>
      <ContentPageTitle title="Delivery Settings" icon="file-pen" />
      <div className="content-box" style={{ padding: 20 }}>
        <Tabs defaultActiveKey="1" type="card" size="default">
          <Tabs.TabPane tab="Delivery Info" key="1">
            <DeliveryInfo
              business={business}
              setDeliveryType={setDeliveryType}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Delivery Prices" key="2">
            <DeliveryPricesInfo
              business={business}
              deliveryType={deliveryType}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default DeliverySettings;
