import React, { useEffect, useState } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { useManagementOrders } from "../../../../hooks/useManagementOrders";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link } from "react-router-dom";
// import { getRestaurantsList } from "../../../../features/reducers/restaurants";
import OrderSettings from "./OrderSettings";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import { Tabs } from "antd";

export default function ManageOrders(props) {
  const { business } = props;

  // Initiate action to get user's business list
  const user = useSelector((state) => state.user.user);

  //   const dispatch = useDispatch();
  // const restaurantsList = useSelector((state) => {
  //     console.log('state', state)
  //     return state.restaurants.restaurantsList;
  // });

  const [ordersList, setOrdersList] = useState({
    shippedOrders: [],
    pendingPaymentOrders: [],
    completedOrders: [],
    canceledOrders: [],
    processingOrders: [],
  });

  const getAllOrders = async () => {
    const APIURL = `/api/restaurant/order/allOrdersFromRestaurant/${business.id}`;

    axiosWithAuth()
      .get(APIURL)
      .then((res) => {
        // const orderList = res.data.data.map((order) => {
        //   return {
        //     ...order,
        //     key: order.id,
        //   };
        // });

        setOrdersList(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    getAllOrders();
  }, [business.id]);

  const [ListComponent] = useManagementOrders(getAllOrders);

  return (
    <div id="restaurant-page">
      <ContentPageTitle title="Manage Orders" icon="file-pen" />

      <div className="content-box">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Tabs defaultActiveKey="1" type="card" size="default">
                  <Tabs.TabPane tab="Shipped Orders" key="1">
                    <ListComponent
                      emptyMessage="Seems like you don't have an orders listing."
                      listItems={ordersList.shippedOrders}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Pending Payment Orders" key="2">
                    <ListComponent
                      emptyMessage="Seems like you don't have an orders listing."
                      listItems={ordersList.pendingPaymentOrders}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Completed Orders" key="3">
                    <ListComponent
                      emptyMessage="Seems like you don't have an orders listing."
                      listItems={ordersList.completedOrders}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Canceled Orders" key="4">
                    <ListComponent
                      emptyMessage="Seems like you don't have an orders listing."
                      listItems={ordersList.canceledOrders}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Processing Orders" key="5">
                    <ListComponent
                      emptyMessage="Seems like you don't have an orders listing."
                      listItems={ordersList.processingOrders}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </>
            }
            exact
          />

          <Route
            path="/:id"
            element={<OrderSettings ordersList={ordersList} />}
            exact
          />
        </Routes>
      </div>
    </div>
  );
}
