const dashboardMenu = [
  {
    title: "Home",
    key: "home",
    capability: "manage_business",
    icon: "house-chimney",
    items: [],
  },
  {
    title: "My Business",
    key: "main-1",
    capability: "manage_business",
    icon: "briefcase",
    items: [
      {
        label: "Information",
        slug: "business-information",
        capability: "manage_business",
        parent: "main-1",
      },
      {
        label: "Business Description",
        slug: "business-description",
        capability: "manage_business",
        parent: "main-1",
      },
      {
        label: "Profile Settings",
        slug: "profile-settings",
        capability: "access_networking_basic",
        parent: "main-1",
      },
      {
        label: "Business Analytics",
        slug: "analytics",
        capability: "manage_business",
        parent: "main-1",
      },
    ],
  },
  {
    title: "Payout Settings",
    key: "payout-settings",
    capability: "access_networking_plus",
    icon: "money-bill-transfer",
    items: [],
  },
  {
    title: "Virtual Office",
    key: "main-3",
    // capability: "access_virtual_office",
    capability: "manage_business",
    icon: "square-phone",
    items: [
      {
        label: "Services",
        slug: "services",
        // capability: "access_virtual_office",
        capability: "manage_business",
        parent: "main-3",
      },
      {
        label: "Appointments",
        slug: "appointments",
        // capability: "access_virtual_office",
        capability: "manage_business",
        parent: "main-3",
      },
    ],
  },
  {
    title: "Restaurant",
    key: "main-2",
    // capability: "access_restaurant",
    capability: "manage_business",
    icon: "utensils",
    items: [
      {
        label: "Product",
        slug: "menu",
        // capability: "access_restaurant",
        capability: "manage_business",
        parent: "main-2",
      },
      {
        label: "Product Categories",
        slug: "product_categories",
        // capability: "access_restaurant",
        capability: "manage_business",
        parent: "main-2",
      },
      {
        label: "Addons",
        slug: "product_addons",
        // capability: "access_restaurant",
        capability: "manage_business",
        parent: "main-2",
      },
      {
        label: "Orders",
        slug: "orders",
        // capability: "access_restaurant",
        capability: "manage_business",
        parent: "main-2",
      },
      {
        label: "Delivery Settings",
        slug: "delivery_settings",
        // capability: "access_restaurant",
        capability: "manage_business",
        parent: "main-2",
      },
      {
        label: "Pickup Settings",
        slug: "pickup_settings",
        // capability: "access_restaurant",
        capability: "manage_business",
        parent: "main-2",
      },
      {
        label: "Import Products",
        slug: "import_products",
        // capability: "access_restaurant",
        capability: "manage_business",
        parent: "main-2",
      },
    ],
  },
  {
    title: "Networking",
    key: "main-4",
    capability: "access_networking_basic",
    icon: "users",
    items: [
      {
        label: "Messages",
        slug: "messages",
        capability: "access_networking_basic",
        parent: "main-4",
      },
      // {
      //   label: "Groups",
      //   slug: "groups",
      //   capability: "access_networking_basic",
      //   parent: "main-4",
      // },
      {
        label: "Followers",
        slug: "followers",
        capability: "access_networking_basic",
        parent: "main-4",
      },
      // {
      //   label: "User Reviews",
      //   slug: "reviews",
      //   capability: "access_networking_basic",
      //   parent: "main-4",
      // },
    ],
  },
  {
    title: "My Events",
    key: "main-5",
    capability: "access_networking_plus",
    icon: "calendar-days",
    items: [
      {
        label: "Manage",
        slug: "events",
        capability: "access_networking_plus",
        parent: "main-5",
      },
    ],
  },
  {
    title: "Advertisements",
    key: "advertisements",
    capability: "access_networking_basic",
    icon: "bullhorn",
    items: [],
  },
  // {
  //   title: "Logout",
  //   key: "logout",
  //   capability: "manage_business",
  //   icon: "right-from-bracket",
  //   items: [],
  // },
];

module.exports = {
  dashboardMenu,
};
