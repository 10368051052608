import React, { useState, useEffect } from "react";
import {
  Form,
  Table,
  Button,
  Modal,
  InputNumber,
  Input,
  message,
  Space,
} from "antd";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

const DeliveryPricesInfo = ({ business, deliveryType }) => {
  const [pricingType, setPricingType] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [zones, setZones] = useState([]);
  const [editingZone, setEditingZone] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchZones();
  }, [business.id]);

  useEffect(() => {
    setPricingType(deliveryType);
    console.log("deliveryType", deliveryType);
  }, [deliveryType]);

  useEffect(() => {
    axiosWithAuth()
      .get(`/api/restaurant/shipping/deliveryGetSettings/${business.id}`)
      .then((response) => {
        console.log("response", response);
        setPricingType(response.data.type);
      })
      .catch(() => {
        message.error("Error loading delivery settings.");
      });
  }, [business.id]);

  const fetchZones = async () => {
    try {
      const response = await axiosWithAuth().get(
        `/api/restaurant/shipping/deliveryZonesGetAll/${business.id}`
      );

      const formattedZones = response.data.map((zone) => ({
        key: zone.id,
        ...zone,
      }));

      setZones(formattedZones);
    } catch (error) {
      message.error("Failed to load delivery zones.");
    }
  };

  const handleAddOrUpdateZone = async (values) => {
    const postalCodesArray = values.postal_codes
      ? values.postal_codes.split(",").map((code) => code.trim())
      : [];
    const uniquePostalCodes = [...new Set(postalCodesArray)];

    if (!uniquePostalCodes.every((code) => /^\d{5}$/.test(code))) {
      message.error("All postal codes must be 5 digits long and unique.");
      return;
    }

    const newZone = {
      restaurant_id: business.id,
      zone_name: values.zone_name,
      postal_codes: uniquePostalCodes.join(","),
      delivery_fee: values.delivery_fee,
      distance_range: pricingType === "distance" ? values.distance_range : null,
      type: pricingType,
    };

    try {
      const response = await axiosWithAuth().post(
        "/api/restaurant/shipping/deliveryZoneCreate",
        newZone
      );

      fetchZones();
      setModalVisible(false);
      setEditingZone(null);
      form.resetFields();
      message.success("Delivery zone saved successfully!");
    } catch (error) {
      message.error("Failed to save delivery zone. Please try again.");
    }
  };

  const handleDelete = async (idZone) => {
    try {
      await axiosWithAuth().delete(
        `/api/restaurant/shipping/deliveryZone/${idZone}`
      );
      setZones((prevZones) => prevZones.filter((zone) => zone.id !== idZone));
      message.success("Delivery zone deleted successfully!");
    } catch (error) {
      message.error("Failed to delete delivery zone. Please try again.");
    }
  };

  // Editar una zona
  const handleEdit = (record) => {
    setEditingZone(record);
    setPricingType(record.type); // Asegurar que el tipo de pricing se mantenga al editar
    form.setFieldsValue(record);
    setModalVisible(true);
  };

  // Columnas de la tabla
  const columns = [
    { title: "Zone Name", dataIndex: "zone_name", key: "zone_name" },
    { title: "Postal Codes", dataIndex: "postal_codes", key: "postal_codes" },
    {
      title: "Delivery Fee ($)",
      dataIndex: "delivery_fee",
      key: "delivery_fee",
    },
    {
      title: "Distance Range (km)",
      dataIndex: "distance_range",
      key: "distance_range",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <button onClick={() => handleEdit(record)}>Manage</button>
          <button onClick={() => handleDelete(record.id)}>Delete</button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {deliveryType && (
        <>
          <Button
            type="primary"
            onClick={() => {
              form.resetFields();
              setEditingZone(null);
              setModalVisible(true);
            }}
          >
            Add Pricing Rule
          </Button>

          <Table
            columns={columns}
            dataSource={zones}
            style={{ marginTop: 16 }}
          />

          <Modal
            title={editingZone ? "Edit Pricing Rule" : "Add Pricing Rule"}
            open={modalVisible}
            onCancel={() => {
              setModalVisible(false);
              setEditingZone(null);
              form.resetFields();
            }}
            onOk={() => form.submit()}
          >
            <Form
              form={form}
              onFinish={handleAddOrUpdateZone}
              layout="vertical"
            >
              <Form.Item
                name="zone_name"
                label="Zone Name"
                rules={[
                  { required: true, message: "Please enter a zone name" },
                ]}
              >
                <Input />
              </Form.Item>
              {pricingType === "fixed" && (
                <Form.Item
                  name="postal_codes"
                  label="Postal Codes"
                  rules={[
                    {
                      required: true,
                      message: "Enter at least one postal code",
                    },
                  ]}
                >
                  <Input placeholder="E.g.: 28001,28002" />
                </Form.Item>
              )}
              {pricingType === "distance" && (
                <Form.Item
                  name="distance_range"
                  label="Distance Range"
                  rules={[{ required: true, message: "Enter distance range" }]}
                >
                  <Input placeholder="E.g.: 0-5" />
                </Form.Item>
              )}
              <Form.Item
                name="delivery_fee"
                label="Delivery Fee ($)"
                rules={[{ required: true, message: "Enter a delivery fee" }]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </div>
  );
};

export default DeliveryPricesInfo;
