//** Import Components */
import { useState, useEffect } from "react";
import {
  Table,
  Empty,
  Space,
  Tag,
  Form,
  Modal,
  Button,
  Select,
  message,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { TABLE_DATE_FORMAT } from "../common/constants";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import axiosWithAuth from "../utils/axiosWithAuth";

//** Separate antd Select Components */
const { Option } = Select;

export const useManagementOrders = (getAllOrders) => {
  // const navigate = useNavigate();
  const dateFormat = TABLE_DATE_FORMAT;

  let navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [modalForm] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusValue, setStatusValue] = useState({
    status: "",
  });
  const [orderID, setOrderID] = useState("");

  //* functions to handle modal
  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const submitModalForm = () => {
    setIsSubmitting(true);
    modalForm.submit();
    closeModal();
  };

  //* Function to submit new status
  const onFinish = (value) => {
    const APIURL = `/api/restaurant/order/${orderID}`;

    console.log("value", value);

    const listingData = {
      order_status: value.status,
    };

    axiosWithAuth()
      .put(APIURL, listingData)
      .then(() => {
        let config = {
          content: `Status edited!`,
          style: {
            background: "none",
          },
        };
        message.success(config);

        closeModal();
        setIsSubmitting(false);
        modalForm.resetFields();

        getAllOrders();
      })
      .catch((err) => {
        console.log(err.response);
        message.error(err.message);
        setIsSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setIsSubmitting(false);
  };

  const columns = [
    {
      title: "Order ID",
      key: "order_id",
      render: (text) => <>{text.id}</>,
    },
    {
      title: "Date Created",
      key: "created",
      render: (text) => (
        <>{moment(new Date(text && text.date_order)).format(dateFormat)}</>
      ),
    },
    {
      title: "Delivery Type",
      key: "delivery_type",
      render: (text) => <>{text.delivery_type}</>,
    },
    {
      title: "Status",
      key: "staus",
      render: (text) => <>{text.order_status}</>,
    },
    {
      title: "Total Amount",
      key: "total_amount",
      render: (text) => <>$ {text.total_price_order}</>,
    },
    // {
    //   title: "Status",
    //   key: "status",
    //   render: (text) => (
    //     <>
    //       {
    //         text.order_status === 'Placed' &&
    //         <Tag icon={<ExclamationCircleOutlined />} color="error">
    //           {text && text.order_status}
    //         </Tag>
    //       }
    //       {
    //         text.order_status === 'Making' &&
    //         <Tag icon={<SyncOutlined spin />} color="processing">
    //           {text && text.order_status}
    //         </Tag>
    //       }
    //       {
    //         (text.order_status === 'On Its Way' || text.order_status === 'Ready For Pickup' ) &&
    //         <Tag icon={<ClockCircleOutlined />} color="warning">
    //           {text && text.order_status}
    //         </Tag>
    //       }
    //       {
    //         (text.order_status === 'Delivered' || text.order_status === 'Picked Up') &&
    //         <Tag icon={<CheckCircleOutlined />} color="success">
    //           {text && text.order_status}
    //         </Tag>
    //       }
    //     </>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      render: (text) => (
        <Space size="middle">
          <button
            onClick={() => {
              navigate(`${text.id}`);
            }}
          >
            View Details
          </button>
          <button
            onClick={() => {
              console.log("text", text);
              setOrderID(text.id);
              setStatusValue({
                status: text.order_status,
              });
              showModal();
            }}
          >
            Edit Status
          </button>
        </Space>
      ),
    },
  ];

  const ListComponent = ({ emptyMessage, listItems }) => {
    return (
      <>
        {listItems.length > 0 ? (
          <>
            <Table columns={columns} dataSource={listItems} />
            <Modal
              open={openModal}
              title="Select Status"
              onCancel={closeModal}
              footer={[
                <Button key="back" onClick={closeModal}>
                  Close
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={isSubmitting}
                  onClick={submitModalForm}
                >
                  Submit
                </Button>,
              ]}
            >
              <Form
                form={modalForm}
                layout="vertical"
                name="userForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select a option"
                    defaultValue={statusValue.status}
                  >
                    <Option value="Pending Payment">Pending Payment</Option>
                    <Option value="Processing">Processing</Option>
                    <Option value="Shipped">Shipped</Option>
                    <Option value="Completed">Completed</Option>
                    <Option value="Canceled">Canceled</Option>
                  </Select>
                </Form.Item>
              </Form>
            </Modal>
          </>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>{emptyMessage}</span>}
          />
        )}
      </>
    );
  };

  return [ListComponent];
};
