import React from "react";
// import { Tabs } from "antd";
import PickupInfo from "./PickupInfo";
// import DeliveryInfo from "./DeliveryInfo";

const PickupDeliverySettings = (props) => {
  const { business } = props;

  return (
    <div className="content-box" style={{ padding: 20 }}>
      <PickupInfo business={business} />
      {/* <Tabs defaultActiveKey="pickup" type="card" size="default">
        <Tabs.TabPane tab="Pickup" key="pickup">
          <PickupInfo business={business} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Delivery" key="delivery">
          <DeliveryInfo business={business} />
        </Tabs.TabPane>
      </Tabs> */}
    </div>
  );
};

export default PickupDeliverySettings;
