import React, { useEffect, useState } from "react";
import ContentPageTitle from "../../../common/ContentPageTitle";
import { useManagementOrdersUser } from "../../../hooks/useManagementOrdersUser";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link } from "react-router-dom";
import { getRestaurantsList } from "../../../features/reducers/restaurants";
import OrderSettingsUser from "./OrderSettingsUser";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import { Tabs } from "antd";

export default function ManageOrdersUser(props) {
  const { user } = props;

  // // Initiate action to get user's user list
  // const user = useSelector((state) => state.user.user);

  //   const dispatch = useDispatch();
  // const restaurantsList = useSelector((state) => {
  //     console.log('state', state)
  //     return state.restaurants.restaurantsList;
  // });

  const [ordersList, setOrdersList] = useState({
    shippedOrders: [],
    pendingPaymentOrders: [],
    completedOrders: [],
    canceledOrders: [],
    processingOrders: [],
  });

  useEffect(() => {
    getAllOrders();
  }, [user.id]);

  // Custom hook to manage the creation/deletion of user listing
  const hookData = {
    creatorID: user.id,
    dispatchAction: getRestaurantsList(user.id),
    deleteItemAPI: "/api/products/delete",
    user: user,
    itemType: "products",
    type: "restaurant",
    modalData: {
      title: "Create New Menu Item",
      fieldLabel: "Menu Item Name",
    },
  };

  const getAllOrders = async () => {
    try {
      const response = await axiosWithAuth().get(
        `/api/restaurant/order/allOrdersFromUser`
      );

      setOrdersList(response.data.data);
    } catch (error) {
      console.error("Error getting orders:", error);
    }
  };

  const [/*modalComponent,*/ ListComponent] = useManagementOrdersUser(hookData);

  return (
    <div id="orders-page">
      <div className="content-box">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Tabs defaultActiveKey="1" type="card" size="default">
                  <Tabs.TabPane tab="Shipped Orders" key="1">
                    <ListComponent
                      emptyMessage="Seems like you don't have an orders listing."
                      listItems={ordersList.shippedOrders}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Pending Payment Orders" key="2">
                    <ListComponent
                      emptyMessage="Seems like you don't have an orders listing."
                      listItems={ordersList.pendingPaymentOrders}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Completed Orders" key="3">
                    <ListComponent
                      emptyMessage="Seems like you don't have an orders listing."
                      listItems={ordersList.completedOrders}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Canceled Orders" key="4">
                    <ListComponent
                      emptyMessage="Seems like you don't have an orders listing."
                      listItems={ordersList.canceledOrders}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Processing Orders" key="5">
                    <ListComponent
                      emptyMessage="Seems like you don't have an orders listing."
                      listItems={ordersList.processingOrders}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </>
            }
            exact
          />

          <Route path="/:id" element={<OrderSettingsUser />} exact />
        </Routes>
      </div>

      {/* {modalComponent} */}
    </div>
  );
}
