import React, { useState, useEffect } from "react";
import {
  Form,
  Checkbox,
  TimePicker,
  InputNumber,
  Button,
  message,
  Select,
} from "antd";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import moment from "moment";

const { Option } = Select;

const DeliveryInfo = ({ business, setDeliveryType }) => {
  const [form] = Form.useForm();
  const [is_enabled, setEnabled] = useState(false);

  useEffect(() => {
    axiosWithAuth()
      .get(`/api/restaurant/shipping/deliveryGetSettings/${business.id}`)
      .then((response) => {
        const data = response.data;
        setEnabled(data.is_enabled);
        form.setFieldsValue({
          is_enabled: data.is_enabled,
          delivery_time: data.delivery_time
            ? moment(data.delivery_time, "HH:mm")
            : null,
          free_delivery_min_amount: data.free_delivery_min_amount || null,
          type: data.type,
        });
      })
      .catch(() => {
        message.error("Error loading delivery settings.");
      });
  }, [business.id, form]);

  // const handleSave = async () => {
  //   try {
  //     const values = await form.validateFields();
  //     const payload = {
  //       is_enabled: values.is_enabled,
  //       delivery_time: values.delivery_time
  //         ? values.delivery_time.format("HH:mm")
  //         : null,
  //       free_delivery_min_amount: values.free_delivery_min_amount || null,
  //       id_restaurant: business.id,
  //       type: values.type,
  //     };

  //     await axiosWithAuth().put(
  //       `/api/restaurant/shipping/deliverySettingsUpdate`,
  //       payload
  //     );
  //     setDeliveryType(values.type);
  //     message.success("Delivery settings updated successfully!");
  //   } catch (error) {
  //     message.error("Failed to update delivery settings.");
  //   }
  // };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const payload = {
        is_enabled: values.is_enabled,
        delivery_time: values.delivery_time
          ? values.delivery_time.format("HH:mm")
          : null,
        free_delivery_min_amount: values.free_delivery_min_amount || null,
        id_restaurant: business.id,
        type: values.type,
      };

      await axiosWithAuth().put(
        `/api/restaurant/shipping/deliverySettingsUpdate`,
        payload
      );

      setDeliveryType(values.type); // Solo se actualiza después de guardar
      message.success("Delivery settings updated successfully!");
    } catch (error) {
      message.error("Failed to update delivery settings.");
    }
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item name="is_enabled" valuePropName="checked">
        <Checkbox onChange={(e) => setEnabled(e.target.checked)}>
          Enable Delivery
        </Checkbox>
      </Form.Item>

      <Form.Item name="type">
        <Select
          placeholder="Select pricing type"
          // onChange={(value) => setDeliveryType(value)}
          style={{ width: "100%", marginBottom: 16 }}
        >
          <Option value="fixed">By Zip Code</Option>
          <Option value="distance">By Distance</Option>
        </Select>
      </Form.Item>

      <Form.Item name="delivery_time" label="Estimated Delivery Time">
        <TimePicker format="HH:mm" disabled={!is_enabled} showNow={false} />
      </Form.Item>

      <Form.Item
        name="free_delivery_min_amount"
        label="Free Delivery Minimum Amount"
      >
        <InputNumber
          min={0}
          step={0.01}
          disabled={!is_enabled}
          style={{ width: "100%" }}
          formatter={(value) => `$ ${value}`}
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        />
      </Form.Item>

      <Button type="primary" onClick={handleSave} disabled={!is_enabled}>
        Save
      </Button>
    </Form>
  );
};

export default DeliveryInfo;
